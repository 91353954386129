<div *ngIf="isLoaded" class="maincontainer margin-y-3 margin-y-md-0 padding-x-1 padding-x-md-0">
  <div class="display-flex flex-column text-white justify-content-center align-items-center margin-top-md-4 margin-bottom-3 font-size-2 font-size-md-3">
      <h3 class="margin-0" style="font-weight: 600;"><span class="margin-right-05" style="color: #27CE8E;">Track</span> <span class="text-white">Delivery</span></h3>
  </div>

  <div class="display-flex justify-content-between">
      <a class="text-white font-regular" style="text-decoration: none;" href="/"><i class="fa-regular fa-circle-arrow-left margin-right-05 text-white"></i><span class="text-link">Back</span></a>
      <a class="text-white font-regular" style="text-decoration: none;" href="https://drivendelivery.co.uk/contact/" target="_blank"><span class="text-link">Need Help with an order?</span> <i class="fa-regular fa-circle-info margin-left-05 text-white"></i></a>
  </div>

  <div class="border-radius-large background-white margin-y-2 padding-1">
    <div class="container b-row">
      <div class="b-col-12 b-col-md-6 margin-y-1">
        <div class="">
          <p class="bold margin-y-05">Delivery Address:</p>
        </div>
        <div class="">
            <p class="margin-y-05" *ngIf="trackingInfo.TrackingAddress.AddressLine1">{{ trackingInfo.TrackingAddress.Name }}</p>
            <p class="margin-y-05" *ngIf="trackingInfo.TrackingAddress.AddressLine1">{{ trackingInfo.TrackingAddress.AddressLine1 }}</p>
            <p class="margin-y-05" *ngIf="trackingInfo.TrackingAddress.TownCity">{{ trackingInfo.TrackingAddress.TownCity }}</p>
            <p class="margin-y-05" *ngIf="trackingInfo.TrackingAddress.Postcode">{{ trackingInfo.TrackingAddress.Postcode }}</p>
        </div>
      </div>
      <div class="b-col-12 b-col-md-6 margin-y-1" *ngIf="trackingInfo.TrackingSO.SONumber">
        <div class="">
          <p class="bold margin-y-05">Order Number:</p>
        </div>
        <div class="">
          <p class="margin-y-05">{{ trackingInfo.TrackingSO.SONumber }}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngFor="let DN of DNs;let arrayIndex = index;" class="border-radius-large background-white margin-y-2 padding-1 padding-md-2">
    <div class="">
      <div class="b-row">
        <p class="b-col-12 margin-y-05">Delivery {{ arrayIndex + 1 }} of {{ DNs.length }}</p>
        <p class="b-col-12 margin-y-05" *ngIf="!DN.expanded">{{ getLatestEvent(DN.DNNumber).TrackingDateTime | date:'dd LLL y HH:mm' }}</p>
        <p class="b-col-12 margin-y-05 bold" *ngIf="!DN.expanded">{{ getLatestEvent(DN.DNNumber).TrackingDescription }}</p>
      </div>
      <div *ngIf="DN.expanded">
        <div *ngFor="let parcel of isParcelDelivered(DN.DNNumber)">
          <div class="b-row margin-x-0">
              <p class="text-white background-green margin-x-0 padding-05 padding-x-05 padding-x-md-15 border-radius-large display-flex align-items-center line-height-13"><i class="fa-solid fa-circle-check margin-right-05"></i> <span>Your order has been delivered at {{ parcel.TrackingDateTime | date:'shortTime' }} on {{ parcel.TrackingDateTime | date:'EEE d LLL y' }}</span></p>
          </div>
          <div class="b-row margin-y-1 justify-content-center justify-content-md-start">
            <div *ngFor="let image of getImages(DN.DNNumber)" class="margin-x-1">
              <img [src]="image.TrackingDescription">
            </div>
          </div>
        </div>
        <table class="table table-bordered margin-y-2 font-size-08 font-size-md-1">
            <thead>
            <tr>
                <th class="col text-align-left">Date &amp; Time</th>
                <th class="col text-align-left">Location</th>
                <th class="col text-align-left">Description</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let event of getParcelHistory(DN.DNNumber)">
                    <td class="col">{{ event.TrackingDateTime | date:'dd LLL y HH:mm' }}</td>
                    <td class="col">{{ event.Location }}</td>
                    <td class="col">{{ event.TrackingDescription }}</td>
                </tr>
            </tbody>
        </table>
      </div>
      <div class="b-row margin-y-1">
        <div *ngIf="DN.expanded" href="#" class="b-col-12 display-flex align-items-center justify-content-center" (click)="toggleExpand(DN)" style="cursor: pointer;"><i class="fa-regular fa-circle-minus fa-lg"></i></div>
        <div *ngIf="!DN.expanded" href="#" class="b-col-12 display-flex align-items-center justify-content-center" (click)="toggleExpand(DN)" style="cursor: pointer;"><i class="fa-regular fa-circle-plus fa-lg"></i></div>
      </div>
    </div>
  </div>
</div>
