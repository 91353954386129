import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DN } from '../shared/model/DN';
import { ParcelHistory } from '../shared/model/ParcelHistory';
import { TrackingLine } from '../shared/model/TrackingLine';
import { TrackingService } from '../shared/tracking.service';

@Component({
    selector: 'app-tracking-page',
    templateUrl: './tracking-page.component.html',
    styleUrls: ['./tracking-page.component.css']
})
export class TrackingPageComponent implements OnInit, OnDestroy {

    searchSub = new Subscription();

    trackingInfo: any;
    delivered: boolean = false;

    DNs: DN[] = [];

    isLoaded: boolean = false;

    constructor(private service: TrackingService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {

        this.route.data.subscribe((info: any) => {
            if (info.trackingInfo == undefined) {
                this.router.navigate(['/']);
            }
            else {
                this.trackingInfo = info.trackingInfo;
                this.isLoaded = true;
                this.getDNs();
            }
        });
    }

    ngOnDestroy() {
        this.searchSub.unsubscribe();
    }

    getDNs() {
        if (this.trackingInfo["TrackingLines"].length > 1) {
            this.trackingInfo["TrackingLines"].forEach((info: TrackingLine, index: number) => {
                if (!this.DNs.some((DN: DN) => DN.DNNumber == info.DNNumber)) {
                    this.DNs.push({ DNNumber: info.DNNumber, expanded: (index == 0) ? true : false });
                }
            });
        }
        else {
            if (!this.DNs.some((DN: DN) => DN.DNNumber == this.trackingInfo["TrackingLines"]["TrackingLine"]["DNNumber"])) {
                this.DNs.push({ DNNumber: this.trackingInfo["TrackingLines"]["DNNumber"], expanded: true });
            }
        }
        //console.log(this.DNs);
    }

    isParcelDelivered(DN: string) {
        if (this.trackingInfo["TrackingLines"].length > 1) {
            return this.trackingInfo["TrackingLines"].filter((info: TrackingLine) => {
                return info["TrackingCode"] == "CompletedOK" && info["DNNumber"] == DN;
            });
        }
    }

    getImages(DN: string) {
        return this.trackingInfo["TrackingLines"].filter((info: TrackingLine) => {
            return info["TrackingCode"] == 'Image' && info["DNNumber"] == DN;
        });
    }

    getParcelHistory(DN: string) {
        if (this.trackingInfo["TrackingLines"].length > 1) {
            return this.trackingInfo["TrackingLines"].filter((info: TrackingLine) => {
                return info.TrackingCode !== 'Image' && info.DNNumber == DN;
            });
        }
        else {
            var trackingInfo: TrackingLine[] = [];
            trackingInfo.push(this.trackingInfo["TrackingLines"]);
            return trackingInfo;
        }
    }

    getLatestEvent(DN: string) {
        return this.trackingInfo["TrackingLines"].find((info: TrackingLine) => {
            return info["DNNumber"] == DN;
        });
    }

    toggleExpand(DN: DN) {
        DN.expanded = !DN.expanded;
    }
}
