import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TrackingService {

    trackingInfo: any;

    constructor(private http: HttpClient) { }

    private url: string = '';

    getTrackingInfo(GUID_search: string, search_OrderNumber: string, search_postcode: string) {
        this.url = environment.trackingAPIURL;
        if (GUID_search.length > 0) {
            this.trackingInfo = this.http.get(this.url + '?GUID=' + GUID_search);
        }
        else {
            this.trackingInfo = this.http.get(this.url + '?OrderNumber=' + search_OrderNumber + '&Postcode=' + search_postcode);
        }
        return this.trackingInfo;
    }

    returnTrackingInfo() {
        return this.trackingInfo;
    }
}
