import { Component, OnDestroy, OnInit } from '@angular/core';
import { DN } from '../shared/model/DN';
import { Subscription } from 'rxjs';
import { TrackingService } from '../shared/tracking.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-tracking-search-page',
    templateUrl: './tracking-search-page.component.html',
    styleUrls: ['./tracking-search-page.component.css']
})
export class TrackingSearchPageComponent implements OnInit, OnDestroy {

    referenceError: string = '';
    postcodeError: string = '';

    currentDate: string = '';

    trackingInfo: any;
    delivered: boolean = false;

    routeSub: Subscription = new Subscription;
    searchSub: Subscription = new Subscription;

    loading: boolean = false;
    GUIDSearchLoading: boolean = false;

    constructor(private service: TrackingService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        if (this.router.url != '/') {
            this.GUIDSearchLoading = true;
            this.routeSub = this.route.params.subscribe((params: any) => {
                this.findOrderByGUID(params['id']);
            });
        }
        else {
            this.currentDate = (new Date()).toISOString().substring(0, 10);
        }
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
        this.searchSub.unsubscribe();
    }

    findOrder(reference: string, postcode: string) {
        this.trackingInfo = {};
        this.delivered = false;
        this.referenceError = '';
        this.postcodeError = '';

        this.loading = true;

        if (reference.length == 0) {
            this.referenceError = 'Please enter order reference';
            this.loading = false;
        }
        if (postcode.length == 0) {
            this.postcodeError = 'Please enter postcode';
            this.loading = false;
        }
        if (reference.length > 0 && postcode.length > 0) {
            if (postcode.indexOf(" ") > -1) {
                postcode = postcode.replace(/\s/ig, '');
            }
            var postcodeRegex = new RegExp('^([Gg][Ii][Rr]0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$');
            if (postcodeRegex.test(postcode)) {
                this.service.getTrackingInfo('', reference, postcode).subscribe({
                    next: (info: any) => {
                        this.trackingInfo = info;
                        //console.log(info);
                        this.checkOutput();
                    },
                    error: (err: any) => {
                        this.referenceError = 'There was a problem getting your order details.  Please try again later';
                        this.loading = false;
                    }
                });
            }
            else {
                this.postcodeError = 'Invalid postcode';
                this.loading = false;
            }
        }
    }

    findOrderByGUID(GUID: string) {
        this.service.getTrackingInfo(GUID, '', '').subscribe({
            next: (info: any) => {
                this.trackingInfo = info;
                this.checkOutput();
            },
            error: (err: any) => {
                this.referenceError = 'There was a problem getting your order details.  Please try again later';
                this.loading = false;
            }
        });
    }

    checkOutput() {
        if (this.trackingInfo !== 'No Tracking Information Available') {
            if (this.trackingInfo.TrackingLines) {
                this.router.navigate(['/tracking']);
            }
            else {
                this.postcodeError = this.trackingInfo;
                this.GUIDSearchLoading = false;
                this.loading = false;
            }
        }
        else {
            this.postcodeError = 'No tracking information available for this order';
            this.GUIDSearchLoading = false;
            this.loading = false;
        }
    }

}
