import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { TrackingResolver } from './shared/tracking.resolver';
import { TrackingPageComponent } from './tracking-page/tracking-page.component';
import { TrackingSearchPageComponent } from './tracking-search-page/tracking-search-page.component';

@NgModule({
  declarations: [
    AppComponent,
    TrackingPageComponent,
    TrackingSearchPageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([
      {
        path: '', component: TrackingSearchPageComponent
      },
      {
        path: 'tracking', component: TrackingPageComponent, resolve: {
          trackingInfo: TrackingResolver
        }
      },
      {
        path: 'GUID/:id', component: TrackingSearchPageComponent
      }
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
