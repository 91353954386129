<div class="maincontainer margin-y-3 margin-y-md-0 padding-x-1 padding-x-md-0">
    <div *ngIf="!GUIDSearchLoading" class="b-row justify-content-center padding-x-1 padding-x-md-0">
        <div class="b-col-12 display-flex flex-column text-white justify-content-center align-items-center margin-top-md-4 font-size-2 font-size-md-3">
            <h3 class="margin-0" style="font-weight: 600;"><span class="margin-right-05" style="color: #27CE8E;">Track</span> <span class="text-white">Delivery</span></h3>
            <p class="margin-0 margin-top-1 text-align-center bold" style="color: #B6B6B6; font-size: 14px;">From the warehouse to the doorstep and every step in between, we are <i>Driven</i> to provide a great home delivery service</p>
        </div>
        <div class="b-col-12 b-col-md-8 border-radius-large border-style-solid border-width-1 padding-1 margin-y-md-5 margin-y-2 border-mid-grey">
            <div>
                <form class="b-row margin-y-1">
                    <div class="b-col-12 b-col-md-6 padding-md-2 padding-1">
                        <label for="referenceInput" class="text-white text-align-center margin-bottom-1">
                            Order Number
                        </label>
                        <input id="referenceInput" #reference placeholder="e.g. Amazon/eBay reference" ng-model="reference" class="form-input" />
                        <p class="text-red text-align-center" *ngIf="referenceError">{{ referenceError }}</p>
                    </div>
                    <div class="b-col-12 b-col-md-6 padding-md-2 padding-1">
                        <label for="postcodeInput" class="text-white text-align-center margin-bottom-1">
                            Postcode
                        </label>
                        <input id="postcodeInput" #postcode placeholder="Postcode" ng-model="postcode" class="form-input" />
                        <p class="text-red text-align-center" *ngIf="postcodeError">{{ postcodeError }}</p>
                    </div>
                    <div class="b-col-12 display-flex justify-content-center margin-top-1 margin-top-md-0">
                        <div *ngIf="loading" class="simple-spinner">
                            <span></span>
                        </div>
                        <button *ngIf="!loading" [disabled]="loading" class="button border-radius-pill text-white margin-0 padding-x-3" style="background-color: #27CE8E;" type="button" (click)="findOrder(reference.value, postcode.value)">Find my order</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="centrebox" *ngIf="GUIDSearchLoading">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
</div>
 